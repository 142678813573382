body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.symbology-icons-container {
  position: absolute;
  bottom: 50px;
  right: 20px;
  z-index: 1;
}

.mc-btn {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.symbology-container {
  transition: all 0.2s ease-out, transform 0.2s ease-out;
  pointer-events: none;
  opacity: 0;
  position: absolute;
  bottom: -100px;
  right: 25px;
  z-index: 1;
  border: 1px solid #444;
  padding: 6px;
  border-radius: 10px;
  font-size: 9pt;
  background-color: rgba(38, 38, 38, 0.75);
}
